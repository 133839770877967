<template>
  <div id="body">
  <!-- 头部 -->
    <div class="page-header">
      <!-- 导航 -->
      <TopBar class="navi" :activeIndex="'4'"/>
      <!-- bannner -->
      <CBanner :bannerCont="bannerCont"/>
    </div>
    <div class="page-container">
      <div id="content">
        <div id="list-l">
          <router-view :key="$route.fullPath"></router-view>
        </div>
        <div id="list-r">        
          <HotNews/>      
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// 导航
import TopBar from './common/TopBar.vue'
// banner
import CBanner from './common/content/CBanner.vue'
// footer
import Footer from './common/Footer.vue'
// 热点新闻
import HotNews from './common/HotNews.vue'

export default {
  name: "solution",
  components: {
    'TopBar': TopBar,
    'CBanner': CBanner,
    'HotNews': HotNews,
    'Footer': Footer
  },
  data() {
    return {
      bannerCont: {
        pic: require('../../assets/show-banner.png'),
        title: "首页",
        txt: "面向实际工程需求攻克核心技术难题，为用户提供多方面的技术支持和定制化的解决方案",
      },
    }
  }, 
  methods: {

  }
}
</script>

<style scoped>
div#body {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
div.page-header {
  position: relative;
  top: 0;
  max-width: 1920px;
  margin: 0 auto;
}
/* 导航 */
.navi {
  position: absolute;
  width: 100%;
  z-index: 200;
}
/* 主体 */
div.page-container {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: 5rem;
}
div.page-container::after {
  content: "";
	clear: both;
	content: '';
	display: block;
	width: 0;
	height: 0;
	visibility: hidden;
}
div#content {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
}
div#list-l {
  width: 70%;
  float: left;
}
div#list-r {
  float: right;
  width: 26%;
}
</style>